<mat-nav-list class="list-horizontal">
<!--    <mat-list-item (click)="onChangeLanguage('en')">-->
<!--        <span *ngIf="multiLanguageService.currentLanguage() == 'en'; else otherEN" style="text-decoration: underline;">EN</span>-->
<!--        <ng-template #otherEN>-->
<!--            EN-->
<!--        </ng-template>-->
<!--    </mat-list-item>-->
<!--    |-->
<!--    <mat-list-item (click)="onChangeLanguage('vn')">-->
<!--        <span *ngIf="multiLanguageService.currentLanguage() == 'vn'; else otherVN" style="text-decoration: underline;">VI</span>-->
<!--        <ng-template #otherVN>-->
<!--            VI-->
<!--        </ng-template>-->
<!--    </mat-list-item>-->

    <mat-list-item (click)="onProfileClicked()" fxShow.lt-md="false">
        {{name}}
    </mat-list-item>

    <mat-list-item (click)="onChangeLanguage('vn')">
        <button [matMenuTriggerFor]="profile" mat-icon-button class="m-r-5">
            <img src="assets/images/users/default-avatar.png" alt="user" class="profile-pic">
        </button>
        <mat-menu #profile="matMenu" class="mymegamenu">
            <!--<button mat-menu-item>-->
            <!--<mat-icon>settings</mat-icon> Settings </button>-->
            <!--<button mat-menu-item>-->
            <!--<mat-icon>account_box</mat-icon> Profile </button>-->
            <!--<button mat-menu-item>-->
            <!--<mat-icon>notifications_off</mat-icon> Disable notifications </button>-->
            <button mat-menu-item [routerLink]="['/authentication/change-password']">
                <mat-icon>vpn_key</mat-icon> Đổi mật khẩu </button>
            <mat-divider></mat-divider>
            <button mat-menu-item (click)="onSignOutClicked()">
                <mat-icon>exit_to_app</mat-icon> Đăng xuất </button>
        </mat-menu>
    </mat-list-item>
</mat-nav-list>