import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {of} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {environment} from "../../../../environments/environment";

@Injectable({
    providedIn: 'root'
})
export class HeaderService {

    constructor(
        private httpClient: HttpClient,
    ) { }

    public getMe() {
        const url = environment.api_base_url + '/me';
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/json')
        ;
        return this.httpClient.get(url, {
                headers: headers,
                // withCredentials: true,
            }
        ).pipe(
            map(results => {
                return results;
            }),
            // catch errors
            catchError(err => {
                console.log(err);
                return of(null);
            })
        );
    }
}
