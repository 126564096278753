import {ChangeDetectorRef, Component, HostBinding, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {NotifierService} from "angular-notifier";
import {AjaxValidatorService} from "../../shared/validator/ajax-validator.service";
import {debounceTime, distinctUntilChanged, flatMap, switchMap} from "rxjs/operators";
import {IResponse} from "../../shared/interfaces/response";
import {of} from "rxjs";
import {Router} from "@angular/router";
import {environment} from "../../../environments/environment";
import {AuthService} from "../auth.service";

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  @HostBinding('class.dv-list-page') layout = true;
  loading = false;
  public form: FormGroup;
  validateUrl = environment.api_base_url + '/auth/change-password-validate';
  version = '';
    isMustChangePassword: boolean = false;


  constructor(
      private fb: FormBuilder,
      private notifier: NotifierService,
      private service: AuthService,
      private router: Router,
      private ajaxValidatorService: AjaxValidatorService,
  ) {

    }

    ngOnInit() {
        if (history.state.data && history.state.data.isMustChangePassword !== undefined) {
            this.isMustChangePassword = history.state.data.isMustChangePassword
        }
        this._getVersion();
        this._buildForm();
    }

    private _buildForm() {
        this.form = this.fb.group({
            old_password: ['', Validators.required],
            new_password: ['', Validators.required],
            new_password_confirm: ['', Validators.required],
        }, {validators: this.checkPasswordConfirm})

        // register events
        this.form.valueChanges.pipe(
            debounceTime(500),
            distinctUntilChanged(),
            switchMap(value => {
                return this.ajaxValidatorService.ajaxValidator(
                    this.form,
                    this.validateUrl
                )
            })
        ).subscribe();
    }

    checkPasswordConfirm(group: FormGroup) { // here we have the 'passwords' group
        let newPassword = group.get('new_password').value;
        let newPasswordConfirm = group.get('new_password_confirm').value;
        return newPassword === newPasswordConfirm ? null : {passwordNotMatch: true}
    }

    private _getVersion() {
        this.service.getVersion().subscribe(res => {
            this.version = res.item.version;
        }, error => {
            this.notifier.notify('error', 'Something went wrong!! Please try again later');
            this.loading = false;
        });
    }

    public onChange() {
        if (!this.form.valid) {
            return;
        }
        const data = this.form.getRawValue();
        data['version'] = this.version;
        this.loading = true;
        this.service.changePassword(data).subscribe((res: IResponse) => {
            if (res.success) {
                this.notifier.notify('success', "Change Password successfully!!!");
                this.router.navigate(['/']);
                this.loading = false;
            } else {
                this.ajaxValidatorService.parseErrors(this.form, res.errors);
                if (res.errors.version) {
                    this.notifier.notify('error', 'Version: ' + res.errors.version[0]);
                } else {
                    this.notifier.notify('error', res.errors[0]);
                }

                this.loading = false;
            }
        }, error => {
            this.notifier.notify('error', 'Something went wrong!! Please try again later');
            this.loading = false;
        });
    }


}
