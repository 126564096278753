import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule
} from '@angular/material';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {Title} from '@angular/platform-browser';

import {RecaptchaModule} from 'ng-recaptcha';
import {NgxMatIntlTelInputModule} from 'ngx-mat-intl-tel-input';
import {AllMaterialModule} from '../../all-material-module';
import {NoPermissionComponent} from './no-permission.component';
import {RouterModule} from '@angular/router';

@NgModule({
    imports: [
        CommonModule,
        MatIconModule,
        MatCardModule,
        MatInputModule,
        MatCheckboxModule,
        MatButtonModule,
        FlexLayoutModule,
        FormsModule,
        ReactiveFormsModule,
        RecaptchaModule,
        NgxMatIntlTelInputModule,
        AllMaterialModule,
        RouterModule
    ],
    declarations: [
        NoPermissionComponent
    ],
    providers: [
        Title,
    ]
})
export class NoPermissionModule {
}
